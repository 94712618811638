<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-form style="padding: 20px 20px 0" :model="queryForm" ref="queryForm" inline>
        <el-form-item label="艺术家昵称">
          <el-input
            v-model="queryForm.artist_name"
            placeholder="请输入艺术家昵称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="UCID">
          <el-input
            v-model="queryForm.artist_ucid"
            placeholder="请输入UCID"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="福袋名称">
          <el-input
            v-model="queryForm.lucky_bag_name"
            placeholder="请输入福袋名称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>

        <el-form-item label="时间段">
          <el-date-picker
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleClickSearch">查询</el-button>
          <el-button type="info" @click="handleClickReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list" border stripe row-key="user_id">
      <el-table-column label="艺术家" prop="artist_name"> </el-table-column>
      <el-table-column label="艺术家UCID" prop="artist_ucid"> </el-table-column>
      <el-table-column label="福袋名称" prop="lucky_bag_name"> </el-table-column>
      <el-table-column label="福袋库存" prop="lucky_bag_stock"> </el-table-column>
      <el-table-column label="福袋价格" prop="lucky_bag_price"> </el-table-column>
      <el-table-column label="在售数量" prop="in_sale_num"> </el-table-column>
      <el-table-column label="待售数量" prop="wait_sale_num"> </el-table-column>
      <el-table-column label="时段内已售数量" prop="sale_num"> </el-table-column>
      <el-table-column label="时段内销售金额" prop="sale_amount"> </el-table-column>
      <el-table-column label="时段内赠送数量" prop="gifted_num"> </el-table-column>
      <el-table-column label="时段内空投数量" prop="airdrop_num"> </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getLuckyBagStatisticList } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        // promotion_type: "",
        // status: "",
      },
      list: [],
      timeRange: [],
    };
  },
  filters: {
    formatDateAtDay: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      if (this.timeRange.length) {
        params.start_at = Math.floor(this.timeRange[0] / 1000);
        params.end_at = Math.floor(this.timeRange[1] / 1000);
      }
      return params;
    },
    async fetchData() {
      try {
        const params = {
          ...this.getPatamsFromQueryForm(),
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getLuckyBagStatisticList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {}
    },
    handleClickSearch() {
      console.log(this.timeRange);
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    handleClickReset() {
      this.resetQuery();
      this.fetchData();
    },
    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.timeRange = [];
      this.queryForm = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
  },
};
</script>
